body {
  background-color: var(--primary);
  margin: 0;
}

:root {
  --primary: #E5521C;
  --primary-util: #FEFEE4;
  --secondary-highlight: #A51E22;
  --secondary-util: #5A3230;
  --tiertiary-highlight: #BAD3EA;
  --saffron: #F9C12E;
  --orange: #FD9337;
  --blue: #1A87B8;
  --gold: #C2AD44;

  --app-height: 100%;
  --app-padding: 20px;
}


@keyframes marquee {
  /* to {
    transform: translate(-50% - 0.5rem);
  } */
  0%   { transform: translateX(0px); }
  100%  { transform: translateX(calc(-120%)); }
}

@keyframes fadeIn {
  /* to {
    transform: translate(-50% - 0.5rem);
  } */
  0%   { opacity: 0%; }
  100%  { opacity: 100%; }
}

@keyframes fadeOut  {
  /* to {
    transform: translate(-50% - 0.5rem);
  } */
  0%   { opacity: 100%; }
  100%  { opacity: 0%; }
}

/* ====== FONTS ====== */

@font-face {
  font-family: "Fivo Sans Modern";   /*Can be any text*/
  src: local("Fivo Sans Moder"),
    url("./fonts/FivoSansModern-Regular.otf") format("truetype");
}

@font-face {
  font-family: "Cubex";   /*Can be any text*/
  src: local("Cubex"),
    url("./fonts/Cubex.otf") format("truetype");
}

@font-face {
  font-family: "Fivo Sans Modern Medium";   /*Can be any text*/
  src: local("Fivo Sans Moder"),
    url("./fonts/FivoSansModern-Medium.otf") format("truetype");
}

h1 {
  font-family: "lorimer-no-2", sans-serif;
  font-size: 90px;
  font-style: normal;
  font-weight: 900;
  text-transform: uppercase;
}

h2 {
  font-family: "lorimer-no-2", sans-serif;
  font-size: 42px;
  font-style: normal;
  font-weight: 900;
}

h3 {
  font-family: "Fivo Sans Modern";
  font-size: 22px;
  font-weight: 400;
}

h4 {
  font-family: "Fivo Sans Modern Medium";
  font-size: 20px;
  font-weight: 900;
}

p {
  font-family: "Fivo Sans Modern";
  font-size: 22px;
  font-weight: 400;
}

ul {
  font-family: "Fivo Sans Modern";
}

a {
  color: inherit;
  transition-duration: 300ms;
}

a:hover {
  color: var(--gold);
  cursor: pointer;
  transition-duration: 300ms;
}

/* ====== MENU ====== */

.nav-wrapper {
  background-color: var(--primary-util);
  border-radius: 0px 0px 10px 10px;
  border-bottom: 2px solid var(--primary);
  box-sizing: border-box;
  color: var(--primary);
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0px;
  width: 25vW;
  z-index: 999;
}

.nav-link-wrapper > h2 > a {
  color: inherit;
  text-decoration: none;
  position: relative;
}

.nav-link-wrapper > h2 > a::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 3px;
  bottom: -2px;
  left: 0;
  transform-origin: 0% 50%;
  background-color: var(--primary);
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.nav-link-wrapper > h2 > a:hover::before {
  transform: scaleX(1);
}

.menu-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 70vH;  
  padding: 0px 20px;
}

.menu-height-cntlr {
  box-sizing: border-box;
  overflow: hidden;
  transition-duration: 300ms;
}

.bottom-menu-tab {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  width: 100%;
}

/* === MENU ICON === */

.menu-icon {
  display: flex;
  height: 25px;
  position: relative;
  transition-duration: 300ms;
  width: 30px;
}

.menu-icon:hover {
  cursor: pointer;
  display: flex;
  row-gap: 0px;
  transition-duration: 300ms;
}

.menu-bar {
  background-color: var(--primary);
  border-radius: 3px;
  height: 3px;
  position: absolute;
  width: 30px;
  transition-duration: 300ms;
  transform-origin: center;
}

#bar1 {
  top: 0;
}

#bar2 {
  top: 44%;
}

#bar3 {
  top: 90%;
}

/* === MENU TABS === */

.tab-wrapper {
  position: relative;
  height: 10vH;
}

.menu-tab {
  border-radius: 0px 0px 10px 10px;
  position: absolute;
  width: 100%;
}

#tab-one {
  background-color: var(--secondary-highlight);
  height: 100%;
  z-index: 1;
}

#tab-two {
  background-color: var(--saffron);
  height: 90%;
  z-index: 2;
}

#tab-three {
  background-color: var(--tiertiary-highlight);
  height: 80%;
  z-index: 3;
}

#tab-four {
  background-color: var(--primary-util);
  height: 70%;
  z-index: 4;
}

.cause-stacked {
  aspect-ratio: 277 / 838;
  height: 90%;
}

/* ====== FOOTER ====== */

.footer-wrapper {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
}

.copyright {
  background-color: var(--primary-util);
  width: 75vW;
}

.copyright p {
  background-color: var(--primary-util);
  color: var(--primary);
  text-align: right;
  padding-right: 20px;
}

.footer-info {
  align-items: center;
  background-color: var(--primary);
  box-sizing: border-box;
  color: var(--primary-util);
  display: flex;
  justify-content: space-between;
  padding: var(--app-padding);
  width: 75vW;
}

.footer-socials {
  display: flex;
  column-gap: 20px;
}

.social-icon {
  width: 30px;
  height: 30px;
}

/* ====== PAGES ====== */

.page-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  position: relative;
  width: 100vW;
}

.page-section {
  display: flex;
  position: relative;
}
.page-wrapper {
  display: flex;
  flex-direction: column;
  width: 100vW;
}

.side-controller {
  align-items: flex-end;
  background-color: var(--primary);
  display: flex;
  height: 88vH;
  justify-content: center;
  top: 0px;
  left: 0px;
  position: fixed;
  width: 25vW;
  z-index: 10;
}

.section-title {
  align-self: flex-start;
  color: var(--primary-util);
  position: sticky;
  top: 92vH;
  width: 25vW;
  text-align: center;
}

/* ====== LANDING PAGE ====== */

.main-content-wrapper {
  display: flex;
  justify-content: space-between;
  height: auto;
  width: 100vW;
}

.column {
  /* flex-direction: column; */
}

.video-container {
  background-color: var(--primary-util);
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 75vW;
}

.intro-video {
  height: 100vH;
  width: 100%;
}

video {
  height: 100vH;
  width: 100%;
  /* object-fit: cover !important; */
}

.tagline-wrapper {
  color: var(--secondary-util);
  padding: var(--app-padding);
  box-sizing: border-box;
}

.tagline-wrapper h1 {
  margin: 100px 0px;
}

.services-container {
  background-color: var(--primary-util);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  row-gap: 10px;
  width: 75vW;
}

.services-desc-text h2 {
  display: none;
}

.services-wrapper {
  display: flex;
  column-gap: 40px;
  height: 186px;
}

.services-title-tag {
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;
  color: var(--primary);
  position: relative;
}

.services-title-tag h2 {
  margin-left: 20px;
  margin-right: 20px;
  white-space: nowrap;
}

.services-desc-tag {
  background-color: var(--primary);
  color: var(--primary-util);
  display: flex;
  height: calc(100% - 4px);
}

#service-title-one {
  width: auto; 
}

#service-description-one {
  width: auto; 
}

.services-desc-text {
  align-items: flex-end;
  display: flex;
  width: 60%; 
}

/* === HEXIGON === */

/* PRIMARY */

.hexigon-body {
  align-content: flex-start;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  width: 20px;
}

.bl-tr-diagonal-services {
  background: var(--primary-util);
  background: linear-gradient(135deg, var(--primary-util) 50%, rgba(229,82,28,1) 50%);
  height: 20px;
  width: 20px;
}

.hexigon-middle {
  background-color: var(--primary);
  height: calc(100% - 40px);
}

.tl-br-diagonal-services {
  background: var(--primary-util);
  background: linear-gradient(45deg, var(--primary-util) 50%, rgba(229,82,28,1) 50%);
  height: 20px;
  width: 20px;
}

/* ORANGE */
.hexigon-body-orange {
  align-content: flex-start;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  width: 20px;
}

.bl-tr-diagonal-orange {
  background: linear-gradient(135deg, var(--primary-util) 50%, var(--orange) 50%);
  height: 20px;
  width: 20px;
}

.hexagon-middle-orange {
  background-color: var(--orange);
  height: calc(100% - 40px);
}

.tl-br-diagonal-orange {
  background: var(--primary-util);
  background: linear-gradient(45deg, var(--primary-util) 50%, var(--orange) 50%);
  height: 20px;
  width: 20px;
}

.tl-br-diagonal-orange-inverse {
  background: var(--primary-util);
  background: linear-gradient(45deg, var(--orange) 50%, var(--primary-util) 50%);
  height: 20px;
  width: 20px;
}

.bl-tr-diagonal-orange-inverse {
  background: var(--orange);
  background: linear-gradient(135deg, var(--orange) 50%, var(--primary-util) 50%);
  height: 20px;
  width: 20px;
}

.bl-tr-diagonal {
  background: var(--primary-util);
  height: 20px;
  width: 20px;
}

.tl-br-diagonal {
  background: var(--primary-util);
  height: 20px;
  width: 20px;
}

.hexagon-middle {
  height: calc(100% - 40px);
}

/* SAFFRON */

.tl-br-diagonal-saffron {
  background: var(--primary-util);
  background: linear-gradient(45deg, var(--primary-util) 50%, var(--saffron) 50%);
  height: 20px;
  width: 20px;
}

.hexigon-middle-saffron {
  background-color: var(--saffron);
  height: calc(100% - 40px);
}

.bl-tr-diagonal-saffron {
  background: var(--primary-util);
  background: linear-gradient(135deg, var(--primary-util) 50%, var(--saffron) 50%);
  height: 20px;
  width: 20px;
}

.tl-br-diagonal-saffron-inverse {
  background: var(--primary-util);
  background: linear-gradient(45deg, var(--saffron) 50%, var(--primary-util) 50%);
  height: 20px;
  width: 20px;
}

.bl-tr-diagonal-saffron-inverse {
  background: var(--primary-util);
  background: linear-gradient(135deg, var(--saffron) 50%, var(--primary-util) 50%);
  height: 20px;
  width: 20px;
}

/* LIGHT BLUE */

.tl-br-diagonal-blue {
  background: var(--primary-util);
  background: linear-gradient(45deg, var(--primary-util) 50%, var(--tiertiary-highlight) 50%);
  height: 20px;
  width: 20px;
}

.hexigon-middle-blue {
  background-color: var(--tiertiary-highlight);
  height: calc(100% - 40px);
}

.bl-tr-diagonal-blue {
  background: var(--primary-util);
  background: linear-gradient(135deg, var(--primary-util) 50%, var(--tiertiary-highlight) 50%);
  height: 20px;
  width: 20px;
}

.tl-br-diagonal-blue-inverse {
  background: var(--primary-util);
  background: linear-gradient(45deg, var(--tiertiary-highlight) 50%, var(--primary-util) 50%);
  height: 20px;
  width: 20px;
}

.bl-tr-diagonal-blue-inverse {
  background: var(--primary-util);
  background: linear-gradient(135deg, var(--tiertiary-highlight) 50%, var(--primary-util) 50%);
  height: 20px;
  width: 20px;
}

.outline-shape {
  position: absolute;
  top: 0px;
  right: -20px;
}

/* === INTRO CASE STUDY === */

.landing-case-study-wrapper {
  background-color: var(--primary-util);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: auto;
  padding: 20px 0px 20px 20px;
  width: 75vW;
}

.intro-line {
  margin-left: -40px;
}

.case-study-intro-line {
  font-family: "lorimer-no-2", sans-serif;
  font-size: 44px;
  font-weight: 400;
  text-transform: none !important;
}

.landing-case-study-wrapper h1 {
  color: var(--primary);
  margin: 0px;
}

.landing-case-study-wrapper h2 {
  color: var(--secondary-util);
  margin: 0px;
  text-transform: uppercase;
}

.landing-case-study-wrapper h3 {
  color: var(--secondary-util);
  margin: 0px;
  width: 50%
}

.intro-gallery-image {
  filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, .25));
  height: 60vH;
  padding: 50px 0px;
  width: auto;
}

.gallery-image {
  filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, .25));
  height: 60vH;
  padding: 50px 0px;
  width: auto;
}

.intro-gallery {
  display: flex;
  overflow-x: clip;
}

.inner {
  display: flex;
  column-gap: 20px;
  width: max-content;
  animation-timeline: view(0px 300px) !important;
  animation: marquee linear;
}

.sticky {
  position: sticky;
}
/* === CONTACT COMPONENT === */

.contact-wrapper {
  background-color: var(--primary-util);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 0px 20px 20px;
  min-height: 100vH;
  width: 75vW;
}

.contact-response {
  color: var(--secondary-util);
}

.contact-wrapper h1 {
  color: var(--secondary-util);
}

form {
  display: flex;
  flex-direction: column;
  row-gap: 80px;
}

input {
  background-color: var(--primary-util);
  border-bottom: 2px solid var(--primary);
  border-top: none;
  border-right: none;
  border-left: none;
  font-family: "Fivo Sans Modern";
  font-size: 30px;
  transition-duration: 200ms;
}

input::placeholder {
  color: var(--primary);
}

.input-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 50px;
}

textarea {
  background-color: var(--saffron);
  border: none;
  color: var(--primary-util);
  font-size: 42px;
  font-family: "lorimer-no-2", sans-serif;
  font-style: normal;
  font-weight: 900;
  height: 40vH;
  padding: var(--app-padding);
}

textarea::placeholder {
  color: var(--primary-util);
}

textarea:focus {
  outline: none;
}

input[type=text] {
  color: var(--primary);
}

input[type=email] {
  color: var(--primary);
}

input:focus {
  outline: none;
  border-bottom: 2px solid var(--blue);
  transition-duration: 200ms;
}


.textarea-wrapper {
  background-color: var(--saffron);
  display: flex;
  width: 60%;
}

.contact-container {
  display: flex;
  justify-content: space-between;
}

.bttn-container {
  display: flex;
  justify-content: center;
}

.contact-bttn {
  background-color: var(--primary-util);
  border: 2px solid var(--primary);
  border-radius: 10px;
  color: var(--primary);
  font-family: "lorimer-no-2", sans-serif;
  font-size: 42px;
  padding: 10px 50px;
  transition-duration: 200ms;
}

.contact-bttn:hover {
  background-color: var(--saffron);
  color: var(--primary-util);
  border: 2px solid var(--primary-util);
  cursor: pointer;
}

/* ====== OUR TEAM ====== */

.team-container {
  background-color: var(--primary-util);
  display: flex;
  flex-direction: column;
  width: 75vW;
  padding: 40px 0px;
  row-gap: 40px;
}

.team-wrapper {
  align-items: stretch;
  display: flex;
  column-gap: 40px;
  height: auto;
  overflow: hidden;
  width: 100%;
}

.team-desc-tag {
  color: var(--primary-util);
  display: flex;
}

.team-image-tag {
  box-sizing: border-box;
  color: var(--primary-util);
  display: flex;
  justify-content: flex-end;
}

.headshot {
  border-radius: 10px;
  max-width: 300px;
  max-height: 300px;
  margin: 20px 0px 20px 0px;
}

.team-desc-text {
  width: 400px;
}

.team-desc-text h2 {
  margin-bottom: 0px;
}

.team-desc-text h4 {
  color: var(--secondary-util);
  margin-top: 0px;
}

.team-desc-text p {
  color: var(--secondary-util);
  font-size: 16px;
}

.easter-egg {
  font-family: lorimer-no-2, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 14px; 
}

#team-headshot-0 {
  width: 40%;
}

#team-description-0 {
  width: 60%;
}

#team-headshot-1 {
  width: 50%;
}

#team-description-1 {
  width: 55%;
}

#team-headshot-2 {
  width: 35%;
}

#team-description-2 {
  width: 65%;
}

/* === CREATIVE PARNTERS ===*/

.creative-partners-wrapper {
  background-color: var(--secondary-util);
  box-sizing: border-box;
  display: flex;
  column-gap: 20px;
  flex-direction: row;
  overflow-x: scroll;
  padding: 50px 40px;
  width: 75vW;
}

.creative-partner-card {
  color: var(--secondary-util);
  display: flex;
  background-color: var(--primary-util);
  flex-direction: column;
  justify-content: space-between;
  max-width: 340px;
}

.partner-headshot {
  max-width: 340px;
}

.creative-partner-card p {
  font-size: 16px;
}

.creative-partner-card h2 {
  font-size: 32px;
}

.creative-partner-description {
  padding: 20px;
}

.hexigon-body-cp {
  align-content: flex-start;
  justify-content: flex-start;
  display: flex;
  height: 20px;
}

.bl-tr-diagonal-cp {
  background: var(--secondary-util);
  background: linear-gradient(135deg, var(--primary-util) 50%, var(--secondary-util) 50%);
  height: 20px;
  width: 20px;
}

.hexigon-middle-cp {
  background-color: var(--primary-util);
  width: calc(100% - 40px);
}

.tl-br-diagonal-cp {
  background: var(--secondary-util);
  background: linear-gradient(45deg, var(--secondary-util) 50%, var(--primary-util) 50%);
  height: 20px;
  width: 20px;
}

/* ====== CASE STUDY ====== */

.case-study-wrapper {
  background-color: var(--primary-util);
  box-sizing: border-box;
  height: auto;
  position: relative;
  width: 75vW;
  z-index: 10;
}

.intro-case-study-wrapper {
  background-color: var(--primary-util);
  box-sizing: border-box;
  min-height: auto;
  position: relative;
  width: 75vW;
  height: auto;
}

.case-study-title {
 padding: 5rem 20px;
}

.case-study-wrapper h1 {
  color: var(--primary);
  margin: 0px;
  max-width: 70%;
}

.case-study-wrapper h2 {
  color: var(--secondary-util);
  margin: 0px;
  text-transform: uppercase;
}

.case-study-wrapper h3 {
  color: var(--secondary-util);
  margin-top: 2rem;
  width: 50%
}

.intro-case-study-wrapper h1 {
  color: var(--primary);
  margin: 0px;
  max-width: 70%;
}

.intro-case-study-wrapper h2 {
  color: var(--secondary-util);
  margin: 0px;
  text-transform: uppercase;
}

.intro-case-study-wrapper h3 {
  color: var(--secondary-util);
  margin-top: 2rem;
  width: 50%
}


.case-study-info {
  align-self: flex-start;
  box-sizing: border-box;
  color: var(--primary-util);
  padding: 20px;
  position: sticky;
  top: 10vH;
  width: 25vW;
}

.case-study-info h2 {
  font-size: 36px;
}

.case-study-info p {
  font-size: 22px;
}

.intro-case-study-image {
  width: 100%;
  margin: 50px 0px;
}

.tag {
  color: var(--saffron);
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0px;
  right: 40px;
  width: 200px;
  z-index: 10;
}

.tag-info {
  align-items: center;
  display: flex;
  background-color: var(--primary);
  flex-direction: column;
  justify-content: space-between;
  height: auto;
  min-height: 60vH;
}

.cause {
  font-family: "Cubex";
  font-size: 40px;
}

.tag-analytics {
  text-align: center;
}

.tag-analytics > div > h3 {
  font-family: "lorimer-no-2", sans-serif;
  font-weight: 900;
  font-size: 36px;
  margin-bottom: 0px;
}

.tag-analytics >  div > h5 {
  color: var(--primary-util);
  font-family: "lorimer-no-2", sans-serif;
  font-weight: 300;
  font-style: normal;
  margin-top: 0px;
  font-size: 20px;
}

.bl-tr-diagonal-tag {
  background: rgba(229,82,28,0) ;
  background: linear-gradient(135deg, rgba(229,82,28,1)  50%, rgba(229,82,28,0)50%);
  height: 20px;
  width: 20px;
}

.hexigon-middle-tag {
  background-color: var(--primary);
  width: calc(100% - 40px);
}

.tl-br-diagonal-tag {
  background: linear-gradient(45deg, rgba(254,254,228,0) 50%, rgba(229,82,28,1) 50%);
  height: 20px;
  width: 20px;
}

/* === GALLERY === */

.case-study-gallery-wrapper {
  align-items: center;
  background-color: var(--primary-util);
  box-sizing: border-box;
  display: flex;
  height: 100vH;
  padding: 20px 0px 20px 0px;
  width: 75vW;
  position: sticky;
  z-index: 10;
}

.case-study-gallery {
  background-size: contain;
  background-repeat: no-repeat;
  background-size: 100vw auto;
  display: flex;
  overflow-x: clip;
  column-gap: 20px;
  padding-left: 20px;
  margin: 5rem 0;
}

/* === VIDEO === */

.gallery-video-container {
  background-color: var(--secondary-util);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 75vW; 
  overflow: hidden;
}

.gallery-video {
  height: auto;
  margin: 100px 0px;
  width: 100%;
  object-fit: cover;
  aspect-ratio: 16 / 9;
}

/* === PARTNERS === */

.partner-container {
  align-items: center;
  background-color: var(--primary-util);
  display: flex;
  min-height: 100vH;
  width: 75vW;
}

.partner-wrapper {
  background-color: var(--saffron);
  border-top: solid var(--primary) 4px;
  border-bottom: solid var(--primary) 4px;
  column-gap: 20px;
  margin: 10rem 0px;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
}

.partner-name {
  color: var(--primary);
  font-size: 60px;
  text-transform: none !important;
  margin: 1rem;
}

.square {
  background-color: var(--primary-util);
  border-radius: 5px;
  height: 30px;
  width: 30px;
}

.partner-card {
  align-items: center;
  column-gap: 20px ;
  display: flex;
}

.partner-card h1 {
  font-size: 44px;
}

.partner-card a {
  text-decoration: inherit;
}



.partner-card a > h1 {
  text-decoration: none;
  position: relative;
}

.partner-card a > h1::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 3px;
  bottom: -2px;
  left: 0;
  transform-origin: 0% 50%;
  background-color: var(--primary);
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.partner-card a > h1:hover::before {
  transform: scaleX(1);
}

.many-more {
  color: var(--primary-util);
  text-align: right;
  font-size: 36px;
  width: 100%;
}

/* === IMPACT === */

.impact-image {
  width: 60vW;
}

.impact-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.impact-tag {
  display: flex;
  background-size: contain;
  background-repeat: no-repeat;
  justify-content: space-around;
  position: relative;
  width: 60vW;
}

.impact-stat {
  align-items: center;
  display: flex;
  flex-direction: column;
  z-index: 2;
}

.impact-stat > h3 {
  color: var(--saffron);
  font-family: "lorimer-no-2", sans-serif;
  font-weight: 900;
  font-size: 36px;
  margin-bottom: 0px;
}

.impact-stat > h5 {
  color: var(--primary-util);
  font-family: "lorimer-no-2", sans-serif;
  font-weight: 300;
  font-style: normal;
  margin-top: 0px;
  font-size: 20px;
}

.impact-background {
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 60vW;
}

/* ====== CASE STUDY LIST ====== */

.main-content-wrapper-work-list {
  background-color: var(--saffron);
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  position: relative;
  height: auto;
  width: 100vW;
}

.case-study-list-container {
  background-color: var(--primary);
  box-sizing: border-box;
  justify-content: center;
  column-gap: 40px;
  row-gap: 40px;
  display: flex;
  flex-wrap: wrap;
  margin: 0px 40px;
  padding: 80px 0;
  width: 70vW;
}

.image-handler {
  background-size: cover;
  background-color: var(--primary);
  background-repeat: no-repeat;
  background-position: center;
  box-sizing: border-box;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: fixed;
  top: 0px;
  width: 25vW;
  height: 100vH;
  left:0px;
  overflow: hidden;
}

.image-handler > h2 {
  color: var(--primary-util);
  z-index: 2;
}

.grid-element {
  display: flex;
  flex-direction: column;
  aspect-ratio: 1 / 1;
  width: auto;
  height: 400px;
  position: relative;
  text-decoration: none;
}

.grid-element > h2 {
  color: var(--primary-util);
  position: relative;
  margin: 40px 20px;
  z-index: 2;
}


.grid-hex {
  fill: var(--gold);
  position: absolute;
  top: 0;
  z-index: 1;
  transition-duration: 300ms;
}

.grid-hex:hover {
  fill: var(--blue);
  transition-duration: 300ms;
}

.image-handler-background {
  position: absolute;
  height: 100%;
  z-index: 1;
  transition-duration: 300ms;
  opacity: 0%;
}

.fadeIn {
  animation: fadeIn 300ms forwards;
}

.fadeOut {
  animation: fadeOut 200ms forwards;
}

.menu-logo {
  display: none;
  width: 50%
}