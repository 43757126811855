@media only screen and (max-width: 900px) {

    body {
      background-color: var(--primary-util);
    }

    h1 {
      font-size: 3em;
    }

    h2 {
      font-size: 2em;
    }

    p {
      font-size: 1em;
    }

    .nav-wrapper {
        width: 100vW;
        z-index: 999;
      }
      
      .nav-link-wrapper > h2 > a {
        color: inherit;
        text-decoration: none;
        position: relative;
      }
      
      .nav-link-wrapper > h2 > a::before {
        content: "";
        position: absolute;
        display: block;
        width: 100%;
        height: 3px;
        bottom: -2px;
        left: 0;
        transform-origin: 0% 50%;
        background-color: var(--primary);
        transform: scaleX(0);
        transition: transform 0.3s ease;
      }
      
      .nav-link-wrapper > h2 > a:hover::before {
        transform: scaleX(1);
      }
      
      .menu-body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 70vH;  
        padding: 0px 20px;
      }
      
      .menu-height-cntlr {
        box-sizing: border-box;
        overflow: hidden;
        transition-duration: 300ms;
      }
      
      .bottom-menu-tab {
        padding: 20px;
        width: 100%;
      }

    .intro-case-study-wrapper {
      width: 100vW;
    }

    
    /* ====== FOOTER ====== */

    .copyright {
        width: 100vW;
    }

    .copyright p {
        background-color: var(--primary-util);
        color: var(--primary);
        text-align: right;
        padding-right: 20px;
    }

    .footer-info {
        align-items: center;
        background-color: var(--primary);
        box-sizing: border-box;
        color: var(--primary-util);
        display: flex;
        justify-content: space-between;
        padding: var(--app-padding);
        width: 100vW;
    }

    .footer-socials {
        display: flex;
        column-gap: 20px;
    }

    .social-icon {
        width: 30px;
        height: 30px;
    }

    /* ====== PAGES ====== */

    .side-controller {
        display: none;
      }

    /* ====== LANDING PAGE ====== */

    .main-content-wrapper {
        flex-direction: column;
        width: 100vW;
      }
    
    .video-container {
        background-color: var(--primary);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: auto;
        width: 100vW;
      }

    .video-container h1 {
      color: var(--primary-util);
    }

      
    .intro-video {
        height: auto;
        width: 100%;
        margin-top: 40px;
      }
    
    .section-title {
        background-color: var(--primary);
        top: 60px;
        width: 100vW;
        text-align: left;
        padding-left: 20px;
        z-index: 9;
      }
    
    .landing-case-study-wrapper {
        background-color: var(--primary-util);
        box-sizing: border-box;
        min-height: 100vH;
        padding: 20px 0px 20px 20px;
        width: 100vW;
      }

    .landing-case-study-wrapper > h3 {
        width: 100%;
      }

      .inner {
        animation: none;
        overflow-x: scroll;
      }

    .services-wrapper {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      height: auto;
    }

    .services-container {
        background-color: var(--primary-util);
        row-gap: 20px;
        width: 100vW;
      }
    
      .services-desc-text {
        align-items: flex-end;
        display: flex;
        width: 100%; 
      }

      .tagline-wrapper > h1 {
        margin: 20px 0px;
      }

      .section-title {
        display: none;
      }

      .services-title-tag {
        display: none;
        justify-content: flex-start;
        margin-bottom: 20px;
        height: auto;
        width: 100%;
      }
      
      .services-title-tag h2 {
        margin-left: 20px;
        margin-right: 20px;
        margin-bottom: 10px;
        white-space: nowrap;
      }
      
      .services-desc-tag {
        background-color: var(--primary-util);
        color: var(--primary-util);
        display: flex;
        justify-content: flex-end;
        width: 100% !important;
      }

      #service-title-one {
        width: auto; 
      }
      
      #service-description-one {
        width: auto; 
      }
      
      .services-desc-text {
        align-items: flex-start;
        flex-direction: column;
        background-color: var(--primary);
        display: flex;
        width: 80%; 
      }


      .services-desc-text h2 {
        display: block;
        margin-bottom: 0px;
      }

      .outline-shape {
        display: none;
        height: 100px;
        width: 100%;
        right: 0px;
      }
      
    /* ====== CONTACT COMPONENT ====== */
    
    .contact-wrapper {
      flex-direction: column;
        width: 100vW;
    }

    .contact-container {
      flex-direction: column;
      box-sizing: border-box;
      row-gap: 20px;
    }

    .input-wrapper {
      width: 100%;
      padding-right: 20px;
      width: calc(100% - 20px);
    }

    .textarea-wrapper {
      width: 100%;
    }

    /* ====== TEAM ====== */

    .team-container {
      width: 100vW;
    }

    .team-wrapper {
      flex-direction: column;
      row-gap: 20px;
    }

    .team-image-tag {
      width: 90% !important;
    }

    .team-desc-tag {
      width: 90% !important;
      align-self: flex-end;
    }

    .creative-partners-wrapper {
      width: 100vW;
      flex-direction: column;
      row-gap: 20px;
    }

    .creative-partner-card {
      background-color: var(--secondary-util);
    }

    .creative-partner-description {
      background-color: var(--primary-util);
    }

    /* ====== OUR WORK ====== */

    .image-handler {
      display: none;
    }

    .case-study-list-container {
      width: 100vW;
      margin: 20px;
    }

    .grid-element {
      height: 300px;
      width: 300px;
    }

    .grid-hex {
      height: 300px;
      width: 300px;
    }

    /* ====== Work View ====== */

    .case-study-info {
      color: var(--secondary-util);
      background-color: var(--primary-util);
      z-index: 1;
      height: 100%;
      position: relative;
      top: 0px;
    }
    .case-study-info h2 {
      margin-top: 0;
    }

    .case-study-title {
      padding-top: 120px;
      padding-bottom: 0px;
    }

    .case-study-info {
      width: 100vW;
    }

    .tag {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 80px;
      height: 100px;
      width: 100vW;
      left: 0px;
    }

    .tag-info {
      flex-direction: row;
      padding: 0px 20px;
      width: 100%;
      min-height: 0;
      height: 100px;

    }

    .tag-analytics {
      flex-direction: row;
    }


    .tag-analytics h3 {
      vertical-align: baseline;
      margin: 0;
    }

    .tag-analytics h5 {
      vertical-align: baseline;
      margin: 0;
    }

    .tag-analytics > div > h3 {
      font-size: 1em;
    }

    .analytic {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      column-gap: 10px;
    }

    .cause {
      font-size: 1.5em;
    }

    .page-container {
      padding-top: 55px;
    }

    .display-none {
    }

    .gallery-video-container {
      width: 100vW;
      z-index: 10 !important;
    }

    .case-study-gallery-wrapper {
      height: auto;
      width: 100vW;
    }

    .case-study-wrapper {
      padding: 20px;
      width: 100vW;
      overflow: hidden;
    }

    .case-study-gallery-wrapper {
      width: 100vW;
    }
    .case-study-gallery {
      box-sizing: border-box;
      padding: 20px;
      margin-top: 0px;
      width: 100vW;
    }
    .mobile-gallery {
      flex-direction: column;
    }

    .gallery-image {
      height: auto;
      padding: 20px 0px;
      width: 100%;
    }

    .partner-container {
      min-height: 0;
      width: 100vW;
      z-index: 10 !important;
    }
    
    .partner-wrapper {
      column-gap: 10px;
      row-gap: 10px;
      margin: 0;
    }

    .partner-card {
      column-gap: 10px;
    }

    .square {
      height: 20px;
      width: 20px;
    }

    .partner-card h1 {
      font-size: 1.75em;
      margin: 0;
    }

    .many-more {
      font-size: 1.75em;
    }

    .display-none {
      display: none;
    }

    .impact-tag {
      background-color: var(--secondary-util);
      border: 2px solid var(--saffron); 
      padding-top: 10px;
      padding-bottom: 10px;
      flex-direction: column;
      align-items: center;
      row-gap: 10px;
      width: 140vW;
    }

    .impact-background {
      display: none;
      width: 400vW;
    }

    .impact-stat {
      column-gap: 10px;
      flex-direction: row;;
    }
    
    .impact-stat h3 {
      margin-top: 0px;
      font-size: 1.5em;
      width: auto;
    }

    .impact-stat h5 {
      margin-bottom: 0px;
      font-size: 1.5em;
      width: auto;
    }

    .impact-image {
      width: 100%;
    }

    .contact-bttn {
      font-size: 2em;
    }

    .menu-logo {
      display: inline;
    }
    

}
